<!-- 雷达库 -->
<template>
  <div
    class="wrap"
    v-infinite-scroll=""
    infinite-scroll-distance="0"
    v-loading="loading"
  >
    <div class="filter cent">
      <el-input
        style="width: 250px"
        placeholder="请输入手机号筛选"
        v-model="keyword"
        class="input-with-select"
      >
        <el-button
          @click="search"
          slot="append"
          icon="el-icon-search"
        ></el-button>
      </el-input>
    </div>
    <br />

    <el-table
      v-loading="loading"
      :data="tableData"
      style="width: 100%; min-height: 68vh"
    >
      <el-table-column
        v-for="(item, index) in table_list"
        :key="index"
        :prop="item.field"
        :label="item.name"
      ></el-table-column>
    </el-table>

    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pageTotal"
        :page-size="15"
        :current-page="pageNow"
        @current-change="pageNowHandler"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { ts_radar_list } from "@/api/tool";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      loading: true,
      keyword: "",
      list: [],
      statusList: [
        { color: "#ccc", type: "未标记" },
        { color: "#F56C6C", type: "不合适" },
        { color: "#E6A23C", type: "未接通" },
        { color: "#67C23A", type: "可面试" },
      ],
      wayList: [
        { icon: "iconfont iconfenxiang", color: "#67c23a" },
        {
          icon: "iconfont iconxiazai",
          color: "#67c23a",
        },
      ],
      //沟通备注
      centerDialogVisible: false,
      remarks: "",
      info: {},
      page: 1,
      jd_edu_name: [
        "学历不限",
        "初中",
        "中专",
        "高中",
        "大专",
        "本科",
        "硕士",
        "博士",
        "MBA",
      ],
      //总页码
      pageTotal: null,
      pageNow: 1,

      tableData: [],
      table_list: [],
    };
  },
  //监听属性 类似于data概念
  computed: {
    newlist() {
      return this.list.filter((item) => {
        if (item.province != "" && item.city != "") {
          item.home = item.province + item.city;
        } else {
          item.home = "未获取";
        }
        item.company_name_one = "";
        item.company_place_one = "";

        item.company_data.filter((v, i) => {
          if (i < 1) {
            item.company_name_one = v.company_name;
            item.company_place_one =
              v.company_place_data.city_name +
              v.company_place_data.district_name;
          } else {
            return;
          }
        });

        return item;
      });
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    search() {
      console.log(this.pageNow);
      ts_radar_list({ page: this.pageNow, keyword: this.keyword }).then(
        (res) => {
          this.loading = false;
          console.log(res);
          if (!res.code) {
            this.tableData = res.data.return_data;
            this.table_list = res.data.title;
            this.pageTotal = res.data.all_count;
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },

    pageNowHandler(page) {
      console.log("pageNowHandler", page);
      if (this.pageNow != page) {
        this.loading = true;
        this.pageNow = page;
        this.search();
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    ts_radar_list({ page: this.pageNow, keyword: this.keyword }).then((res) => {
      this.loading = false;
      console.log(res);
      if (!res.code) {
        this.tableData = res.data.return_data;
        this.table_list = res.data.title;
        this.pageTotal = res.data.all_count;
      } else {
        this.$message.error(res.msg);
      }
    });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.wrap {
  // margin-top: 80px;
  min-height: 80vh;
  @include padding;
  min-width: 1100px;
  padding-bottom: 0;
}
.cent {
  width: 300px;
  margin: 0 auto;
}
.info_wrap {
  width: 800px;
  // height: 80px;
  margin-bottom: 10px;
  background-color: #fff;
  padding: 20px 20px;
  color: $jd_fontColor;
  font-size: $jd_fontSize;
  /* text-align: center; */
  /* line-height: 200px; */
  display: flex;
  position: relative;
  cursor: pointer;
  margin: auto;
}
.info_wrap:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.fontPadding {
  padding-left: 10px;
}
p {
  margin: 0;
  line-height: 27px;
}

img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: auto 0px;
}
.headSvg {
  position: relative;
  left: -10px;
  top: 0;
  z-index: 50;
  margin: 10px 0px auto 0px;
}
/*基本信息 */
.bsaicInfo {
  margin-left: 10px;
  float: left;
  width: 230px;

  /* background-color: red; */
}
// .selfAppraisal {
//   /* 多行超出隐藏 */
//   display: -webkit-box;
//   -webkit-box-orient: vertical;
//   -webkit-line-clamp: 3;
//   overflow: hidden;
//   height: 57px;
//   height: 48px\0;
// }
/* 期望 */
.expect {
  float: left;

  width: 400px;
}
.expect p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.radar-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}
.radar-btn + i {
  position: absolute;
  bottom: 20px;
  right: 10px;
}
.radar-comment {
  margin-left: 7px;
  float: left;
}
.head {
  font-size: 50px;
  color: brown;
}
.is-down {
  width: 50px;
  height: 24px;
  vertical-align: middle;
}
.area {
  border: 1px solid #ff9900;
  width: 450px;
  height: 100px;
  min-width: 450px;
  max-width: 450px;
  font-size: 16px;
  color: $selfColor;
  // text-align: center;
  // margin: 0 auto;
  display: inline-block;
  font-family: auto;
}
.area:focus {
  outline: none !important;
  border: 1px solid #719ece;
  box-shadow: 0 0 10px #719ece;
}

.chat {
  width: 30px;
  height: 30px;
  float: left;
}

.chat_div {
  float: right;
  margin-right: -60px;
  margin-top: 90px;
  line-height: 30px;
}
.iconfont {
  font-size: 28px;
}

.pagination {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
</style>
